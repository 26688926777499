var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { isConnected, signTransaction } from '@stellar/freighter-api';
import isMobile from 'is-mobile';
import { Asset, BASE_FEE, Keypair, Networks, Operation, Server, StrKey, TransactionBuilder, } from 'stellar-sdk';
import API from '../../api';
import { convertDecimal } from '../../utils/convertDecimal';
var ASSET_CODE_MAX_CHARS = 12;
var BASE_RESERVE = 0.5;
var BTBLCK_ASSET_CODE = 'BTBLCK';
var XLM_STROOP_VALUE = 10000000;
var DEFAULT_NUM_OF_ENTRIES_PER_COPY = 3;
var MAX_XLM_VALUE = 214;
var PRICE_LIMIT = 2147483647;
var MAX_NUM_OF_OPERATIONS_PER_TRANSACTION = 100;
var ALL_WALLET_OPTIONS = [
    {
        name: 'freighter',
        displayName: 'Freighter',
        installLink: 'https://www.freighter.app/',
        isMobileSupported: function () { return true; },
        isAvailable: function () {
            return Promise.resolve(isConnected());
        },
    },
];
var IS_MOBILE = isMobile({ tablet: true });
var generateAssetCode = function (name, copy) {
    var number = copy === 1 ? '' : copy.toString();
    return "".concat(name
        .replace(/\s+/g, '-')
        .replace(/\W/g, '')
        .toUpperCase()
        .slice(0, ASSET_CODE_MAX_CHARS - number.length)).concat(number);
};
var getBaseFee = function (server) { return __awaiter(void 0, void 0, void 0, function () {
    var e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, server.fetchBaseFee()];
            case 1: return [2 /*return*/, _a.sent()];
            case 2:
                e_1 = _a.sent();
                return [2 /*return*/, parseInt(BASE_FEE)];
            case 3: return [2 /*return*/];
        }
    });
}); };
// https://developers.stellar.org/docs/glossary/minimum-balance/#stellar-docsearch-form
var getMinimumDistributionBalance = function (numOfCopies, numOfEntriesPerCopy) {
    if (numOfEntriesPerCopy === void 0) { numOfEntriesPerCopy = DEFAULT_NUM_OF_ENTRIES_PER_COPY; }
    var NUM_OF_BTBLCKS_TRUSTLINES = 1;
    return (2 + NUM_OF_BTBLCKS_TRUSTLINES + numOfCopies * numOfEntriesPerCopy) * BASE_RESERVE;
};
/*
  compute number of operations to be done by the distribution account after an asset is purchased,
  to transfer revenue to split accounts, partner and platform accounts.
*/
var getNumOfPostPurchaseOperationsPerCopy = function (nft) {
    var PLATFORM_FEE_PAYMENT = 1;
    var PARTNER_FEE_PAYMENT = 1;
    var numOfSplits = nft.splits ? nft.splits.length : 0;
    return PLATFORM_FEE_PAYMENT + PARTNER_FEE_PAYMENT + numOfSplits;
};
var computeBtblckXlmValue = function (xlmPrice) {
    var value = 1000;
    while (xlmPrice * (XLM_STROOP_VALUE / value) > PRICE_LIMIT) {
        value = value * 10;
    }
    return value;
};
var StellarBackend = /** @class */ (function () {
    function StellarBackend() {
        this.walletOptions = ALL_WALLET_OPTIONS.filter(function (option) { return !IS_MOBILE || option.isMobileSupported(); });
        this.storageWallets = this.walletOptions.map(function (option) { return option.name; });
        this.paymentMethods = this.storageWallets;
        this.defaultPaymentMethod = 'freighter';
        this.defaultStorageWallet = 'freighter';
    }
    StellarBackend.prototype.mint = function (token, account, nft, convertedPrice, onTransactionHash, _contractAddress, _marketContractAbi, domain, setTransactionNumber, setNumOfTransactions) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var api, uri, metadataUri, xlmPrice, useBtblckAsset, btblckXlmValue, btblckPrice, btblckPricePerOneAsset, xlmPricePerOneAsset, baseNumOfOperations, numOfOperationsPerCopy, numOfCopiesPerTransaction, limit, amount, issuingKeys, distributionKeys, server_url, network, server, baseFee, copies, numOfPostPurchaseOperationsPerCopy, distributionAccountBalanceInStroops, distributionAccountBalanceInXlm, minimumDistributionBalance, distributionAccountBalance, betablockAsset, betablockAssetAmount, betablockAssetLimit, numOfTransactions, numOfCopiesForFinalTransaction, transactionHashes, _loop_1, tran, e_2;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        api = new API({ token: token });
                        uri = nft.metadataUrl;
                        if (!nft.ipfsAssetData) return [3 /*break*/, 2];
                        return [4 /*yield*/, api.nfts.ipfs(nft.id)];
                    case 1:
                        metadataUri = (_d.sent()).metadataUri;
                        uri = metadataUri;
                        _d.label = 2;
                    case 2:
                        xlmPrice = convertDecimal(convertedPrice, 7);
                        useBtblckAsset = Number(xlmPrice) > MAX_XLM_VALUE;
                        btblckXlmValue = 0;
                        btblckPrice = 0;
                        btblckPricePerOneAsset = 0;
                        xlmPricePerOneAsset = 0;
                        baseNumOfOperations = 4;
                        numOfOperationsPerCopy = 3;
                        if (useBtblckAsset) {
                            btblckXlmValue = computeBtblckXlmValue(Number(xlmPrice));
                            btblckPrice = Number(xlmPrice) / btblckXlmValue;
                            btblckPricePerOneAsset = Number(xlmPrice) * (XLM_STROOP_VALUE / btblckXlmValue);
                            baseNumOfOperations = baseNumOfOperations + 2;
                            numOfOperationsPerCopy = numOfOperationsPerCopy + 1;
                        }
                        else {
                            xlmPricePerOneAsset = Number(xlmPrice) * XLM_STROOP_VALUE;
                        }
                        numOfCopiesPerTransaction = Math.floor((MAX_NUM_OF_OPERATIONS_PER_TRANSACTION - baseNumOfOperations) / numOfOperationsPerCopy);
                        limit = '0.0000005';
                        amount = '0.0000001';
                        issuingKeys = Keypair.random();
                        distributionKeys = Keypair.random();
                        server_url = (_a = nft.storefrontBlockchainInfo) === null || _a === void 0 ? void 0 : _a.blockchainContract.blockchain.rpcUrl;
                        network = (_b = nft.storefrontBlockchainInfo) === null || _b === void 0 ? void 0 : _b.blockchainContract.blockchain.network;
                        if (!server_url || !network) {
                            console.error('Missing server url or network');
                            return [2 /*return*/];
                        }
                        server = new Server(server_url);
                        return [4 /*yield*/, getBaseFee(server)];
                    case 3:
                        baseFee = _d.sent();
                        copies = (_c = nft.numberOfCopies) !== null && _c !== void 0 ? _c : 1;
                        numOfPostPurchaseOperationsPerCopy = getNumOfPostPurchaseOperationsPerCopy(nft);
                        distributionAccountBalanceInStroops = copies * numOfPostPurchaseOperationsPerCopy * baseFee;
                        distributionAccountBalanceInXlm = distributionAccountBalanceInStroops / XLM_STROOP_VALUE // 10e7
                        ;
                        minimumDistributionBalance = getMinimumDistributionBalance(copies);
                        distributionAccountBalance = minimumDistributionBalance + distributionAccountBalanceInXlm;
                        betablockAsset = new Asset(BTBLCK_ASSET_CODE, issuingKeys.publicKey());
                        betablockAssetAmount = convertDecimal(btblckPrice * copies, 7);
                        betablockAssetLimit = convertDecimal(Number(betablockAssetAmount) * copies + copies, 7);
                        numOfTransactions = Math.ceil(copies / numOfCopiesPerTransaction);
                        if (setNumOfTransactions) {
                            setNumOfTransactions(numOfTransactions);
                        }
                        numOfCopiesForFinalTransaction = copies % numOfCopiesPerTransaction;
                        transactionHashes = [];
                        _d.label = 4;
                    case 4:
                        _d.trys.push([4, 9, , 10]);
                        _loop_1 = function (tran) {
                            return __generator(this, function (_e) {
                                switch (_e.label) {
                                    case 0:
                                        if (setTransactionNumber) {
                                            setTransactionNumber(tran);
                                        }
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        return [4 /*yield*/, server.loadAccount(account).then(function (receiver) { return __awaiter(_this, void 0, void 0, function () {
                                                var baseTransaction, copies, i, assetCode, asset, transaction, signedTransaction, transactionToSubmit, response;
                                                return __generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0:
                                                            baseTransaction = new TransactionBuilder(receiver, {
                                                                fee: BASE_FEE,
                                                                networkPassphrase: Networks[network],
                                                            });
                                                            if (tran === 1) {
                                                                baseTransaction.addOperation(Operation.createAccount({
                                                                    // create issuer account
                                                                    destination: issuingKeys.publicKey(),
                                                                    startingBalance: '1.5', // (2 + (1 data entry)) * 0.5
                                                                }));
                                                                baseTransaction.addOperation(Operation.createAccount({
                                                                    // create distribution account
                                                                    destination: distributionKeys.publicKey(),
                                                                    startingBalance: "".concat(distributionAccountBalance),
                                                                }));
                                                                // mint appropriate amount of btblck tokens and store in distribution account
                                                                if (useBtblckAsset) {
                                                                    baseTransaction
                                                                        .addOperation(Operation.changeTrust({
                                                                        asset: betablockAsset,
                                                                        limit: "".concat(betablockAssetLimit),
                                                                        source: distributionKeys.publicKey(),
                                                                    }))
                                                                        .addOperation(
                                                                    // create intermediary asset
                                                                    Operation.payment({
                                                                        destination: distributionKeys.publicKey(),
                                                                        asset: betablockAsset,
                                                                        amount: "".concat(betablockAssetAmount),
                                                                        source: issuingKeys.publicKey(),
                                                                    }));
                                                                }
                                                            }
                                                            copies = tran === numOfTransactions && numOfCopiesForFinalTransaction > 0
                                                                ? numOfCopiesForFinalTransaction
                                                                : numOfCopiesPerTransaction;
                                                            for (i = 1; i <= copies; i++) {
                                                                assetCode = generateAssetCode(nft.name, (tran - 1) * numOfCopiesPerTransaction + i);
                                                                asset = new Asset(assetCode, issuingKeys.publicKey());
                                                                baseTransaction
                                                                    .addOperation(Operation.changeTrust({
                                                                    asset: asset,
                                                                    limit: limit,
                                                                    source: distributionKeys.publicKey(),
                                                                }))
                                                                    .addOperation(Operation.payment({
                                                                    destination: distributionKeys.publicKey(),
                                                                    asset: asset,
                                                                    amount: amount,
                                                                    source: issuingKeys.publicKey(),
                                                                }));
                                                                if (useBtblckAsset) {
                                                                    baseTransaction
                                                                        .addOperation(Operation.manageSellOffer({
                                                                        buying: betablockAsset,
                                                                        selling: asset,
                                                                        amount: amount,
                                                                        price: "".concat(btblckPricePerOneAsset),
                                                                        offerId: '0',
                                                                        source: distributionKeys.publicKey(),
                                                                    }))
                                                                        .addOperation(Operation.manageSellOffer({
                                                                        buying: Asset.native(),
                                                                        selling: betablockAsset,
                                                                        amount: "".concat(btblckPrice),
                                                                        price: "".concat(btblckXlmValue),
                                                                        offerId: '0',
                                                                        source: distributionKeys.publicKey(),
                                                                    }));
                                                                }
                                                                else {
                                                                    baseTransaction.addOperation(Operation.manageSellOffer({
                                                                        buying: Asset.native(),
                                                                        selling: asset,
                                                                        amount: amount,
                                                                        price: "".concat(xlmPricePerOneAsset),
                                                                        offerId: '0',
                                                                        source: distributionKeys.publicKey(),
                                                                    }));
                                                                }
                                                            }
                                                            if (tran === numOfTransactions) {
                                                                baseTransaction
                                                                    // Freeze issuing keys
                                                                    .addOperation(Operation.setOptions({
                                                                    source: issuingKeys.publicKey(),
                                                                    masterWeight: 0,
                                                                    homeDomain: domain,
                                                                }))
                                                                    .addOperation(
                                                                // https://developers.stellar.org/api/resources/operations/object/manage-data/
                                                                // value must be <= 64 bytes
                                                                Operation.manageData({
                                                                    source: issuingKeys.publicKey(),
                                                                    name: 'ipfshash',
                                                                    value: uri,
                                                                }));
                                                            }
                                                            transaction = baseTransaction.setTimeout(100).build();
                                                            transaction.sign(issuingKeys);
                                                            transaction.sign(distributionKeys);
                                                            return [4 /*yield*/, signTransaction(transaction.toXDR(), network)];
                                                        case 1:
                                                            signedTransaction = _a.sent();
                                                            transactionToSubmit = TransactionBuilder.fromXDR(signedTransaction, server_url);
                                                            return [4 /*yield*/, server.submitTransaction(transactionToSubmit)];
                                                        case 2:
                                                            response = _a.sent();
                                                            transactionHashes = __spreadArray(__spreadArray([], __read(transactionHashes), false), [response.hash], false);
                                                            return [2 /*return*/];
                                                    }
                                                });
                                            }); })];
                                    case 1:
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        _e.sent();
                                        return [2 /*return*/];
                                }
                            });
                        };
                        tran = 1;
                        _d.label = 5;
                    case 5:
                        if (!(tran <= numOfTransactions)) return [3 /*break*/, 8];
                        return [5 /*yield**/, _loop_1(tran)];
                    case 6:
                        _d.sent();
                        _d.label = 7;
                    case 7:
                        tran++;
                        return [3 /*break*/, 5];
                    case 8: return [3 /*break*/, 10];
                    case 9:
                        e_2 = _d.sent();
                        console.error(e_2);
                        onTransactionHash([], "".concat(xlmPrice), issuingKeys.publicKey());
                        return [2 /*return*/];
                    case 10:
                        // distribution secret key is treated as the marketplace contract address
                        onTransactionHash(transactionHashes, "".concat(xlmPrice), issuingKeys.publicKey(), distributionKeys.secret());
                        return [2 /*return*/];
                }
            });
        });
    };
    StellarBackend.prototype.purchase = function (account, contractAddress, tokenId, price, onTransactionHash, _marketContractAbi, nft, edition) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var limit, buyAmount, asset, useBtblckAsset, betablockAsset, path, server_url, network, server, receiver, transaction, signedTransaction, transactionToSubmit, response, e_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        limit = '0.0000005';
                        buyAmount = '0.0000001';
                        if (!nft || !nft.storefrontBlockchainInfo || !edition || !edition.code || !edition.issuer) {
                            console.error('required parameter is undefined');
                            return [2 /*return*/];
                        }
                        asset = new Asset(edition.code, edition.issuer);
                        useBtblckAsset = Number(price) > MAX_XLM_VALUE;
                        betablockAsset = new Asset(BTBLCK_ASSET_CODE, edition.issuer);
                        path = useBtblckAsset ? [betablockAsset] : undefined;
                        server_url = nft.storefrontBlockchainInfo.blockchainContract.blockchain.rpcUrl;
                        network = (_a = nft.storefrontBlockchainInfo) === null || _a === void 0 ? void 0 : _a.blockchainContract.blockchain.network;
                        if (!server_url || !network) {
                            console.error('Missing server url or network');
                            return [2 /*return*/];
                        }
                        server = new Server(server_url);
                        return [4 /*yield*/, server.loadAccount(account)
                            /*
                              use path payment (strict receive) to perform a trade.
                              if using btblck asset (as can be determined by the asset price),
                                  include btblck asset in the path array.
                        
                              given the path, a path payment will automatically execute buy orders based on available sell offers.
                              if using btblck asset, this will execute two buy orders to:
                                (1) purchase BTBLCK using XLM
                                (2) purchase 0.0000001 asset using BTBLCK
                            */
                        ];
                    case 1:
                        receiver = _b.sent();
                        transaction = new TransactionBuilder(receiver, {
                            fee: BASE_FEE,
                            networkPassphrase: Networks[network],
                        })
                            .addOperation(Operation.changeTrust({
                            asset: asset,
                            limit: limit,
                        }))
                            .addOperation(Operation.pathPaymentStrictReceive({
                            sendAsset: Asset.native(),
                            sendMax: "".concat(price),
                            destination: account,
                            destAmount: buyAmount,
                            destAsset: asset,
                            path: path,
                        }))
                            .setTimeout(100)
                            .build();
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 5, , 6]);
                        return [4 /*yield*/, signTransaction(transaction.toXDR(), network)];
                    case 3:
                        signedTransaction = _b.sent();
                        transactionToSubmit = TransactionBuilder.fromXDR(signedTransaction, server_url);
                        return [4 /*yield*/, server.submitTransaction(transactionToSubmit)];
                    case 4:
                        response = _b.sent();
                        onTransactionHash(response.hash);
                        return [3 /*break*/, 6];
                    case 5:
                        e_3 = _b.sent();
                        console.error("Stellar purchase failed: ".concat(e_3));
                        onTransactionHash('');
                        return [2 /*return*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    StellarBackend.prototype.getBalance = function (account, nft) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var server_url, server, stellarAccount, nativeArray;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        server_url = (_a = nft === null || nft === void 0 ? void 0 : nft.storefrontBlockchainInfo) === null || _a === void 0 ? void 0 : _a.blockchainContract.blockchain.rpcUrl;
                        if (!server_url) {
                            return [2 /*return*/, '0'];
                        }
                        server = new Server(server_url);
                        return [4 /*yield*/, server.loadAccount(account).catch(function (err) {
                                console.error(err);
                            })];
                    case 1:
                        stellarAccount = _b.sent();
                        if (!stellarAccount) {
                            return [2 /*return*/, '0'];
                        }
                        nativeArray = stellarAccount.balances.filter(function (balance) { return balance.asset_type === 'native'; });
                        if (!nativeArray) {
                            return [2 /*return*/, '0'];
                        }
                        return [2 /*return*/, nativeArray[0].balance];
                }
            });
        });
    };
    StellarBackend.prototype.validateAddress = function (address) {
        return StrKey.isValidEd25519PublicKey(address);
    };
    StellarBackend.prototype.getTokenIdDisplay = function (tokenId) {
        return tokenId ? "".concat(tokenId.slice(0, 7), "...").concat(tokenId.slice(-10)) : '';
    };
    StellarBackend.prototype.getContractAddressDisplay = function () {
        return '';
    };
    StellarBackend.prototype.getAssetLink = function (explorerUrl, contractAddress, tokenId) {
        return "".concat(explorerUrl, "/asset/").concat(tokenId);
    };
    return StellarBackend;
}());
export { StellarBackend };
