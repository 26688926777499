import { APINFT } from '@betablocks/shared/lib/api/nfts'
import { ChainBackend } from '@betablocks/shared/lib/multichain/base'
import { useBlockchainWallet } from '@betablocks/shared/lib/multichain/utils'
import useChainIds from './useChainIds'

const useMultichain = (
  blockchainType: 'ethereum' | 'stellar',
  isWalletActive = false,
  chainIdsOverride = []
): {
  backend: ChainBackend
  connect: (wallet: string) => Promise<string>
  disconnect: () => Promise<void>
  isConnected: boolean
  address: string | null
  getProvider: (nft: APINFT) => any | undefined // eslint-disable-line @typescript-eslint/no-explicit-any
  getConnector: () => string | null
  isChainIdSelected: (chainId: number) => boolean
  sign: (message: string) => Promise<string>
} => {
  const storefrontChainIds = useChainIds()
  const chainIds = chainIdsOverride.length ? chainIdsOverride : storefrontChainIds

  const {
    connect,
    disconnect,
    isConnected,
    address,
    getProvider,
    getConnector,
    isChainIdSelected,
    sign,
    backend,
  } = useBlockchainWallet(blockchainType, chainIds, isWalletActive)

  return {
    backend,
    connect,
    disconnect,
    isConnected,
    address,
    getProvider,
    getConnector,
    isChainIdSelected,
    sign,
  }
}

export default useMultichain
