var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import detectEthereumProvider from '@metamask/detect-provider';
import BN from 'bn.js';
import isMobile from 'is-mobile';
import Web3 from 'web3';
import API from '../../api';
import appConfig from '../../config/appConfig';
import { convertDecimal } from '../../utils/convertDecimal';
var ALL_WALLET_OPTIONS = [
    {
        name: 'walletconnect',
        displayName: 'Walletconnect',
        installLink: 'https://walletconnect.com/',
        isMobileSupported: function () { return true; },
        isAvailable: function () {
            return Promise.resolve(true);
        },
    },
    {
        name: 'metamask',
        displayName: 'MetaMask',
        installLink: 'https://metamask.io',
        isMobileSupported: function () { return !!(window === null || window === void 0 ? void 0 : window.ethereum); },
        isAvailable: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, detectEthereumProvider({ mustBeMetaMask: true })];
                    case 1: return [2 /*return*/, !!(_a.sent())];
                }
            });
        }); },
    },
];
var IS_MOBILE = isMobile({ tablet: true });
var EthereumBackend = /** @class */ (function () {
    function EthereumBackend() {
        this.walletOptions = ALL_WALLET_OPTIONS.filter(function (option) { return !IS_MOBILE || option.isMobileSupported(); });
        this.storageWallets = this.walletOptions.map(function (option) { return option.name; });
        this.paymentMethods = __spreadArray(['bank', 'credit_card'], __read(this.storageWallets), false);
        this.defaultPaymentMethod = this.storageWallets.includes('metamask')
            ? 'metamask'
            : this.storageWallets[0];
        this.defaultStorageWallet = this.storageWallets.includes('metamask')
            ? 'metamask'
            : this.storageWallets[0];
    }
    EthereumBackend.prototype.setProvider = function (provider) {
        this.web3 = new Web3(provider);
    };
    EthereumBackend.prototype.mint = function (token, account, nft, convertedPrice, onTransactionHash, contractAddress, marketContractAbi, _domain, setTransactionNumber) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var api, uri, metadataUri, numberOfCopies, distributionItems, isSecondTransaction, uris, i, roundedPrice, updatedSplits, gasPrice, marketplaceContract, parameters, price, params;
            var _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        if (!contractAddress) {
                            console.error('Ethereum Backend: contract address is undefined.');
                            return [2 /*return*/];
                        }
                        if (!marketContractAbi) {
                            console.error('Ethereum backend: marketplace contract ABI is undefined.');
                            return [2 /*return*/];
                        }
                        if (!this.web3) {
                            console.error('Ethereum backend: mint called too early.');
                            return [2 /*return*/];
                        }
                        api = new API({ token: token });
                        uri = nft.metadataUrl;
                        if (!nft.ipfsAssetData) return [3 /*break*/, 2];
                        return [4 /*yield*/, api.nfts.ipfs(nft.id)];
                    case 1:
                        metadataUri = (_f.sent()).metadataUri;
                        uri = metadataUri;
                        _f.label = 2;
                    case 2:
                        if (!uri) {
                            return [2 /*return*/];
                        }
                        numberOfCopies = nft.numberOfCopies;
                        // For gifted airdrops, mint editions only for email items in the distribution list.
                        if (nft.saleType === 'airdrop' && nft.airdropType === 'gifted') {
                            distributionItems = (_a = nft === null || nft === void 0 ? void 0 : nft.distributionList) === null || _a === void 0 ? void 0 : _a.items;
                            if (!distributionItems) {
                                return [2 /*return*/];
                            }
                            isSecondTransaction = !!distributionItems.find(function (item) { return !item.email && !!item.walletAddress; });
                            if (isSecondTransaction) {
                                setTransactionNumber === null || setTransactionNumber === void 0 ? void 0 : setTransactionNumber(2);
                            }
                            // Determine how many to mint
                            numberOfCopies =
                                (_b = distributionItems.filter(function (item) { return !!item.email && !item.walletAddress; }).length) !== null && _b !== void 0 ? _b : 0;
                            if (!numberOfCopies) {
                                // nothing to mint here
                                return [2 /*return*/];
                            }
                        }
                        uris = [];
                        for (i = 0; i < numberOfCopies; i++) {
                            uris.push(uri);
                        }
                        roundedPrice = convertDecimal(convertedPrice);
                        updatedSplits = (_c = nft.splits) === null || _c === void 0 ? void 0 : _c.map(function (split) { return ({
                            splitAddress: split.walletAddress,
                            basisPoints: split.percentage * 10,
                        }); });
                        return [4 /*yield*/, this.web3.eth.getGasPrice()];
                    case 3:
                        gasPrice = _f.sent();
                        marketplaceContract = new this.web3.eth.Contract(marketContractAbi, contractAddress);
                        parameters = {
                            from: account,
                            gasPrice: gasPrice,
                        };
                        price = BN.max(this.web3.utils.toBN(1), this.web3.utils.toBN(this.web3.utils.toWei(roundedPrice, 'ether'))).toString();
                        params = [uris, price] // eslint-disable-line @typescript-eslint/no-explicit-any
                        ;
                        if ((_d = nft.storefrontBlockchainInfo) === null || _d === void 0 ? void 0 : _d.hasSplits) {
                            params.push(updatedSplits);
                        }
                        return [2 /*return*/, (_e = marketplaceContract.methods)
                                .mintAndListTokens.apply(_e, __spreadArray([], __read(params), false)).send(parameters)
                                .on('transactionHash', function (hash) {
                                onTransactionHash([hash], price, account);
                            })];
                }
            });
        });
    };
    EthereumBackend.prototype.mintAndTransfer = function (token, account, nft, onTransactionHash, contractAddress, marketContractAbi) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var api, uri, metadataUri, distributionItems, beneficiaries, beneficiaryCopies, uris, i, hasInvalidAddresses, gasPrice, marketplaceContract, parameters;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.web3) {
                            console.error('Ethereum backend: mintAndTransfer called too early.');
                            return [2 /*return*/];
                        }
                        api = new API({ token: token });
                        uri = nft.metadataUrl;
                        if (!(nft === null || nft === void 0 ? void 0 : nft.ipfsAssetData)) return [3 /*break*/, 2];
                        return [4 /*yield*/, api.nfts.ipfs(nft.id)];
                    case 1:
                        metadataUri = (_b.sent()).metadataUri;
                        uri = metadataUri;
                        _b.label = 2;
                    case 2:
                        if (!uri) {
                            return [2 /*return*/];
                        }
                        distributionItems = (_a = nft === null || nft === void 0 ? void 0 : nft.distributionList) === null || _a === void 0 ? void 0 : _a.items;
                        if (!distributionItems) {
                            return [2 /*return*/];
                        }
                        beneficiaries = distributionItems
                            .filter(function (item) { return item.walletAddress; })
                            .map(function (item) { return item.walletAddress; });
                        if (!beneficiaries.length) {
                            return [2 /*return*/];
                        }
                        beneficiaryCopies = beneficiaries.length;
                        uris = [];
                        for (i = 0; i < beneficiaryCopies; i++) {
                            uris.push(uri);
                        }
                        hasInvalidAddresses = !!beneficiaries.find(function (beneficiary) { return !_this.validateAddress(beneficiary || '?'); });
                        if (hasInvalidAddresses) {
                            console.error('Ethereum Backend: some beneficiary addresses are invalid.');
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.web3.eth.getGasPrice()];
                    case 3:
                        gasPrice = _b.sent();
                        marketplaceContract = new this.web3.eth.Contract(marketContractAbi, contractAddress);
                        parameters = {
                            from: account,
                            gasPrice: gasPrice,
                        };
                        return [2 /*return*/, marketplaceContract.methods
                                .mintAndTransferTokens(uris, beneficiaries)
                                .send(parameters)
                                .on('transactionHash', function (hash) {
                                onTransactionHash([hash], '0', account);
                            })];
                }
            });
        });
    };
    EthereumBackend.prototype.purchase = function (account, contractAddress, tokenId, price, onTransactionHash, marketContractAbi) {
        return __awaiter(this, void 0, void 0, function () {
            var gasPrice, marketplaceContract, parameters;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!marketContractAbi) {
                            console.error('Ethereum backend: marketplace contract ABI is undefined.');
                            return [2 /*return*/];
                        }
                        if (!this.web3) {
                            console.error('Ethereum backend: purchase called too early.');
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.web3.eth.getGasPrice()];
                    case 1:
                        gasPrice = _a.sent();
                        marketplaceContract = new this.web3.eth.Contract(marketContractAbi, contractAddress);
                        parameters = {
                            from: account,
                            gasPrice: gasPrice,
                            value: this.web3.utils.toWei(String(price)),
                        };
                        return [2 /*return*/, marketplaceContract.methods
                                .purchaseToken(tokenId)
                                .send(parameters)
                                .on('transactionHash', function (hash) {
                                onTransactionHash(hash);
                            })];
                }
            });
        });
    };
    EthereumBackend.prototype.getBalance = function (account) {
        return __awaiter(this, void 0, void 0, function () {
            var balance;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.web3) {
                            console.error('Ethereum backend: getBalance called too early.');
                            return [2 /*return*/, '0'];
                        }
                        return [4 /*yield*/, this.web3.eth.getBalance(account)];
                    case 1:
                        balance = _a.sent();
                        return [2 /*return*/, this.web3.utils.fromWei(balance)];
                }
            });
        });
    };
    EthereumBackend.prototype.validateAddress = function (address) {
        var web3 = new Web3();
        return web3.utils.isAddress(address);
    };
    EthereumBackend.prototype.getTokenIdDisplay = function (tokenId) {
        return tokenId ? "#".concat(tokenId) : '';
    };
    EthereumBackend.prototype.getContractAddressDisplay = function (contractAddress) {
        return "".concat(contractAddress.slice(0, 6), "...").concat(contractAddress.slice(-4));
    };
    EthereumBackend.prototype.getAssetLink = function (explorerUrl, contractAddress, tokenId) {
        return "".concat(explorerUrl, "/token/").concat(contractAddress, "?a=").concat(tokenId);
    };
    EthereumBackend.prototype.deployTokenContract = function (account, tokenContractAbi, tokenContractByteCode) {
        return __awaiter(this, void 0, void 0, function () {
            var tokenContract, tokenContractAddress, gasPrice, parameters;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.web3) {
                            console.error('Ethereum backend: deployTokenContract called too early.');
                            return [2 /*return*/];
                        }
                        tokenContract = new this.web3.eth.Contract(tokenContractAbi);
                        tokenContractAddress = '';
                        return [4 /*yield*/, this.web3.eth.getGasPrice()];
                    case 1:
                        gasPrice = _a.sent();
                        parameters = {
                            from: account,
                            gasPrice: gasPrice,
                        };
                        return [4 /*yield*/, tokenContract
                                .deploy({
                                data: tokenContractByteCode,
                            })
                                .send(parameters)
                                .on('error', function (error) {
                                console.log(error);
                                return;
                            })
                                .on('receipt', function (receipt) {
                                console.log('Contract Address', receipt.contractAddress); // contains the new contract address
                                tokenContractAddress = receipt.contractAddress;
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, tokenContractAddress];
                }
            });
        });
    };
    EthereumBackend.prototype.deployMarketContract = function (account, marketContractAbi, marketContractByteCode, tokenContractAddress) {
        return __awaiter(this, void 0, void 0, function () {
            var marketContract, marketAddress, gasPrice, parameters;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.web3) {
                            console.error('Ethereum backend: deployMarketContract called too early.');
                            return [2 /*return*/];
                        }
                        marketContract = new this.web3.eth.Contract(marketContractAbi);
                        marketAddress = '';
                        return [4 /*yield*/, this.web3.eth.getGasPrice()];
                    case 1:
                        gasPrice = _a.sent();
                        parameters = {
                            from: account,
                            gasPrice: gasPrice,
                        };
                        return [4 /*yield*/, marketContract
                                .deploy({
                                data: marketContractByteCode,
                                arguments: [tokenContractAddress],
                            })
                                .send(parameters)
                                .on('error', function (error) {
                                console.log(error);
                                return;
                            })
                                .on('receipt', function (receipt) {
                                console.log('Contract Address', receipt.contractAddress); // contains the new contract address
                                marketAddress = receipt.contractAddress;
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, marketAddress];
                }
            });
        });
    };
    EthereumBackend.prototype.linkMarketToToken = function (account, tokenContractAbi, tokenContractAddress, marketContractAddress, onTransactionHash) {
        return __awaiter(this, void 0, void 0, function () {
            var tokenContract, gasPrice, parameters;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.web3) {
                            console.error('Ethereum backend: linkMarketToToken called too early.');
                            return [2 /*return*/];
                        }
                        tokenContract = new this.web3.eth.Contract(tokenContractAbi, tokenContractAddress);
                        return [4 /*yield*/, this.web3.eth.getGasPrice()];
                    case 1:
                        gasPrice = _a.sent();
                        parameters = {
                            from: account,
                            gasPrice: gasPrice,
                        };
                        return [4 /*yield*/, tokenContract.methods
                                .setMarketplace(marketContractAddress)
                                .send(parameters)
                                .on('transactionHash', function (hash) {
                                onTransactionHash(hash);
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EthereumBackend.prototype.setPlatformMarket = function (account, marketContractAbi, marketContractAddress, onTransactionHash) {
        return __awaiter(this, void 0, void 0, function () {
            var marketContract, gasPrice, parameters;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.web3) {
                            console.error('Ethereum backend: setPlatformMarket called too early.');
                            return [2 /*return*/];
                        }
                        marketContract = new this.web3.eth.Contract(marketContractAbi, marketContractAddress);
                        return [4 /*yield*/, this.web3.eth.getGasPrice()];
                    case 1:
                        gasPrice = _a.sent();
                        parameters = {
                            from: account,
                            gasPrice: gasPrice,
                        };
                        return [4 /*yield*/, marketContract.methods
                                .setPlatformAddress(appConfig.platformAddress)
                                .send(parameters)
                                .on('transactionHash', function (hash) {
                                onTransactionHash(hash);
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EthereumBackend.prototype.setPartnerMarket = function (account, marketContractAbi, marketContractAddress, onTransactionHash) {
        return __awaiter(this, void 0, void 0, function () {
            var marketContract, gasPrice, parameters;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.web3) {
                            console.error('Ethereum backend: setPartnerMarket called too early.');
                            return [2 /*return*/];
                        }
                        marketContract = new this.web3.eth.Contract(marketContractAbi, marketContractAddress);
                        return [4 /*yield*/, this.web3.eth.getGasPrice()];
                    case 1:
                        gasPrice = _a.sent();
                        parameters = {
                            from: account,
                            gasPrice: gasPrice,
                        };
                        return [4 /*yield*/, marketContract.methods
                                .setPartnerAddress(appConfig.partnerAddress)
                                .send(parameters)
                                .on('transactionHash', function (hash) {
                                onTransactionHash(hash);
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return EthereumBackend;
}());
export { EthereumBackend };
