import { Slide, toast } from 'react-toastify';
var Toast = /** @class */ (function () {
    function Toast() {
    }
    Toast.success = function (content, toastId, bodyClass) {
        if (bodyClass === void 0) { bodyClass = 'break-words'; }
        return toast.success(content, {
            toastId: toastId,
            transition: Slide,
            bodyClassName: bodyClass,
        });
    };
    /** Shows a toast message styled as a "danger" or an "error" message. */
    Toast.danger = function (content, toastId, autoClose, bodyClass) {
        if (bodyClass === void 0) { bodyClass = 'break-words'; }
        return toast.error(content, {
            toastId: toastId,
            transition: Slide,
            autoClose: autoClose,
            bodyClassName: bodyClass,
        });
    };
    /** Shows a toast message styled as an "info" message. */
    Toast.info = function (content, toastId, autoClose, delay, bodyClass) {
        if (bodyClass === void 0) { bodyClass = 'break-words'; }
        return toast.info(content, {
            toastId: toastId,
            transition: Slide,
            autoClose: autoClose,
            delay: delay,
            bodyClassName: bodyClass,
        });
    };
    return Toast;
}());
export { Toast };
