import useEthereumWallet from '../ethereum/useEthereumWallet';
import useStellarWallet from '../stellar/useStellarWallet';
export function useBlockchainWallet(blockchainType, chainIds, isActive) {
    if (isActive === void 0) { isActive = false; }
    var ethereumWallet = useEthereumWallet(chainIds, isActive);
    var stellarWallet = useStellarWallet();
    switch (blockchainType) {
        case 'ethereum':
            return ethereumWallet;
        case 'stellar':
        default:
            return stellarWallet;
    }
}
