var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import detectEthereumProvider from '@metamask/detect-provider';
import { useWeb3React } from '@web3-react/core';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAsync } from 'react-use';
import { useWallet } from 'use-wallet';
import { getChainInformation } from '../utils/chains';
import { Toast } from '../utils/toast';
var useWalletButtons = function (chainIds, isActive) {
    if (isActive === void 0) { isActive = false; }
    var wallet = useWallet();
    var web3ReactContext = useWeb3React();
    var _a = __read(useState(false), 2), isConnected = _a[0], setIsConnected = _a[1];
    var t = useTranslation(['common']).t;
    var _b = __read(useState(false), 2), isEthereumInjected = _b[0], setIsEthereumInjected = _b[1];
    var connect = useCallback(function (connectorId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, wallet.connect(connectorId)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [wallet]);
    var disconnect = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(wallet.status !== 'disconnected' &&
                        wallet.status !== 'error' &&
                        wallet.connector !== 'injected' &&
                        wallet.connector !== 'walletlink')) return [3 /*break*/, 2];
                    if (!(web3ReactContext.connector && web3ReactContext.connector.close)) return [3 /*break*/, 2];
                    return [4 /*yield*/, web3ReactContext.connector.close()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    wallet.reset();
                    return [2 /*return*/];
            }
        });
    }); }, [wallet, web3ReactContext.connector]);
    useAsync(function () { return __awaiter(void 0, void 0, void 0, function () {
        var provider;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, detectEthereumProvider()];
                case 1:
                    provider = _a.sent();
                    if (provider) {
                        setIsEthereumInjected(true);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, []);
    var isNetworkSupported = useMemo(function () {
        if (!wallet.chainId) {
            return true;
        }
        return chainIds.includes(wallet.chainId);
    }, [chainIds, wallet.chainId]);
    var disabled = useMemo(function () {
        return !!wallet.account && !isNetworkSupported;
    }, [isNetworkSupported, wallet.account]);
    useEffect(function () {
        if (wallet.account && isNetworkSupported && isActive) {
            toast.dismiss();
            Toast.success(t('wallet.connectedWalletToast', { address: wallet.account }), undefined, 'break-all');
            setIsConnected(true);
        }
    }, [isNetworkSupported, t, wallet.account, isActive]);
    useEffect(function () {
        if (!wallet.account && isConnected) {
            toast.dismiss();
            toast.dismiss('unsupportedNetwork');
            setIsConnected(false);
        }
    }, [wallet.account, isConnected, t]);
    useEffect(function () {
        if (wallet.error) {
            toast.dismiss();
            if (wallet.error.name === 'ConnectionRejectedError') {
                return;
            }
            if (wallet.error.name === 'ChainUnsupportedError') {
                Toast.danger(t('wallet.unsupportedNetworkToast', {
                    networks: chainIds.map(function (chainId) { var _a; return (_a = getChainInformation(chainId)) === null || _a === void 0 ? void 0 : _a.fullName; }).join(', '),
                }));
                localStorage.removeItem('walletconnect');
            }
            else {
                Toast.danger(wallet.error.message);
            }
        }
    }, [t, wallet.error, chainIds]);
    var showUnsupportedNetworkToast = function () {
        Toast.danger(t('wallet.unsupportedNetworkToast', {
            networks: chainIds.map(function (chainId) { var _a; return (_a = getChainInformation(chainId)) === null || _a === void 0 ? void 0 : _a.fullName; }).join(', '),
        }), 'unsupportedNetwork', false);
    };
    var _c = __read(useState(function () {
        return debounce(showUnsupportedNetworkToast, 300);
    }), 1), debouncedShowUnsupportedNetworkToast = _c[0];
    useEffect(function () {
        if (wallet.account && !isNetworkSupported) {
            debouncedShowUnsupportedNetworkToast();
        }
        else if (wallet.account && isNetworkSupported) {
            toast.dismiss('unsupportedNetwork');
        }
    }, [isNetworkSupported, wallet.account, debouncedShowUnsupportedNetworkToast]);
    var isUnwrappedRpcResult = useCallback(function (response) {
        return typeof response === 'object' && response !== null && 'jsonrpc' in response;
    }, []);
    var rpcResult = useCallback(function (response) {
        // Some providers don’t wrap the response
        if (isUnwrappedRpcResult(response)) {
            if (response.error) {
                throw new Error(response.error);
            }
            return response.result || null;
        }
        return response || null;
    }, [isUnwrappedRpcResult]);
    var ethereumRequest = useCallback(
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    function (method, params) { return __awaiter(void 0, void 0, void 0, function () {
        var ethereum;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ethereum = wallet.ethereum;
                    if (!(ethereum && ethereum['request'])) return [3 /*break*/, 2];
                    return [4 /*yield*/, ethereum['request']({ method: method, params: params }).then(rpcResult)];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    // This is specific to some older versions of MetaMask combined with Web3.js.
                    if (ethereum['sendAsync'] && ethereum['selectedAddress']) {
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                ethereum['sendAsync']({
                                    method: method,
                                    params: params,
                                    from: ethereum['selectedAddress'],
                                    jsonrpc: '2.0',
                                    id: 0,
                                }, 
                                // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                                function (err, result) {
                                    if (err) {
                                        reject(err);
                                    }
                                    else {
                                        resolve(result);
                                    }
                                });
                            }).then(rpcResult)];
                    }
                    // If none of the previous two exist, we assume the provider is pre EIP-1193,
                    // using .send() rather than .request().
                    if (ethereum['send']) {
                        return [2 /*return*/, ethereum['send'](method, params).then(rpcResult)];
                    }
                    throw new Error('The Ethereum provider doesn’t seem to provide a request method.');
            }
        });
    }); }, [rpcResult, wallet]);
    var sign = useCallback(function (message) { return __awaiter(void 0, void 0, void 0, function () {
        var resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    toast.dismiss();
                    Toast.info(t('wallet.signTransaction'), 'sign', false, 1000);
                    resp = '';
                    return [4 /*yield*/, ethereumRequest('personal_sign', [message, wallet.account])
                            .then(function (response) {
                            resp = response;
                        })
                            .catch(function () {
                            toast.dismiss();
                            Toast.danger(t('wallet.failedToSignNonceToast'));
                            resp = 'rejected';
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/, resp];
            }
        });
    }); }, [ethereumRequest, t, wallet.account]);
    var status = wallet.status;
    return useMemo(function () { return ({
        connect: connect,
        disconnect: disconnect,
        status: status,
        isEthereumInjected: isEthereumInjected,
        sign: sign,
        wallet: wallet,
        disabled: disabled,
    }); }, [connect, disconnect, status, wallet, isEthereumInjected, sign, disabled]);
};
export default useWalletButtons;
